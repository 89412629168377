import React from "react";
import {
  Box,
  Button,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import theme from "../../../utils/theme";
import { useCardDetails } from "./hook";
import { FormFooter } from "../../atoms/FormFooter";
import { RequestResponseView } from "../../molecules/RequestResponseView";

const useStyles = makeStyles((theme) => ({
  form: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "6px",
    width: "45%",
    padding: "14px",
    height: "fit-content",
    marginTop: "28px",
  },
  element: {
    height: "37px",
    marginTop: "12px",
    backgroundColor: "#EAF0F6",
    fontWeight: 500,
  },
  button: {
    textTransform: "none",
    width: "100%",
    borderRadius: "6px",
  },
  apiView: {
    background: theme.palette.grey[100],
    padding: "14px 5px 0px 14px",
    marginTop: "28px",
    maxHeight: "inherit",
  },
  formHighlight: {
    border: `1px solid ${theme.palette.grey[300]}`,
    width: "45%",
    padding: "14px",
    height: "fit-content",
    marginTop: "28px",
    borderRadius: "6px",
    animation: "$pulse-black 1500ms infinite",
    "&:hover": {
      animation: "none",
    },
  },
  buttonHighlight: {
    textTransform: "none",
    width: "95.5%",
    marginLeft: "10px",

    borderRadius: "6px",
    marginTop: "12px",
    padding: "5px",

    animation: "$pulse-black 1500ms infinite",
  },

  "@keyframes pulse-black": {
    "0%": {
      boxShadow: "0px 0px 2px 4px #4169e1",
    },

    "50%": {
      boxShadow: "0px 0px 0px 0px white",
    },

    "100%": {
      boxShadow: "0px 0px 2px 4px #4169e1",
    },
  },

  "@keyframes bounce": {
    "0%": {
      transform: "translateY(0)",
    },
    "50%": {
      transform: "translateY(4px)",
    },
    "100%": {
      transform: "translateY(0)",
    },
  },
}));

export const CardDetailsStep = ({
  activeStep,
  accessToken,
  setTokens,
  tokens,
  handleNext,
  response,
  setResponse,
}) => {
  const {
    collectLoading,
    tabIndex,
    collectError,
    tabs,
    handleGenerate,
    setTabIndex,
    handleSubmitCardDetails,
    nameRef,
    cardRef,
    monthRef,
    yearRef,
    cvvRef,
    isSubmitDisabled
  } = useCardDetails(
    activeStep,
    accessToken,
    setTokens,
    tokens,
    handleNext,
    response,
    setResponse
  );

  const classes = useStyles();
  return (
    <Box display="flex">
      <Box
        className={
          activeStep == 0 && !collectLoading
            ? classes.formHighlight
            : classes.form
        }
      >
        <Box>
          <Typography variant="caption" color="textSecondary">
            Name on Card
          </Typography>
        </Box>

        <Box
          key={"name"}
          style={{
            height: "55px",
            marginTop: "12px",
          }}
          ref={nameRef}
          id="name"
        />

        <Box
          style={{
            backgroundColor: "#F5F8FA",
            padding: "14px",
            borderRadius: "4px",
          }}
        >
          <Box>
            <Typography variant="caption" color="textSecondary"></Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="caption" color="textSecondary">
                Card Number
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                }}
                onClick={
                  activeStep == 0 && !collectLoading ? handleGenerate : null
                }
              >
                Generate CC data
              </Typography>
            </Box>

            <Box
              key={"card"}
              disabled={true}
              className={classes.element}
              ref={cardRef}
              id="card"
            />
          </Box>
          <Box marginTop="15px" display="flex">
            <Box>
              <Typography variant="caption" color="textSecondary">
                Expiration Month
              </Typography>
              <Box
                key={"month"}
                disabled={true}
                className={classes.element}
                ref={monthRef}
                id="month"
              />
            </Box>
            <Box marginLeft="28px">
              <Typography variant="caption" color="textSecondary">
                Expiration Year
              </Typography>

              <Box>
                <Box
                  key={"year"}
                  disabled={true}
                  className={classes.element}
                  ref={yearRef}
                  id="year"
                />
              </Box>
            </Box>
          </Box>
          <Box marginTop="15px">
            <Typography variant="caption" color="textSecondary">
              CVV
            </Typography>

            <Box
              key={"cvv"}
              className={classes.element}
              ref={cvvRef}
              id="cvv"
            />
          </Box>
        </Box>
        <Box
          className={
            !(isSubmitDisabled || collectLoading)
              ? classes.buttonHighlight
              : classes
          }
          marginTop="12px"
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={isSubmitDisabled}
            startIcon={
              collectLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                undefined
              )
            }
            onClick={handleSubmitCardDetails}
          >
            {collectLoading ? "Submitting" : "Submit"}
          </Button>
        </Box>
        {collectError && (
          <Typography
            variant="overline"
            color="error"
            style={{ textTransform: "none" }}
          >
            Something went wrong while submitting data.
          </Typography>
        )}
        <FormFooter collectError={collectError} />
      </Box>
      <Box
        marginLeft="40px"
        marginTop="28px"
        width="55%"
        height="463px"
        className={classes.apiView}
      >
        <RequestResponseView
          tabs={tabs}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          response={response}
        />
      </Box>
    </Box>
  );
};
