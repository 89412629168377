import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Typography, Box } from "@material-ui/core";


const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      fontWeight: 500,
    },
    mediumLabel: {
      margin: theme.spacing(0),
    },
    smallLabel: {
      margin: theme.spacing(0, 1),
    },
    tabsRoot: {
      minHeight: 0,
      padding: theme.spacing(0),
      margin: theme.spacing(0),
    },
    tabsIndicator: {
      backgroundColor: theme.palette.primary.main,
      height: theme.spacing(0.5),
    },
    tabsIndicatorInverted: {
      backgroundColor: "black",
      height: theme.spacing(0.5),
    },
    tabRoot: {
      minWidth: 1,
      minHeight: 0,
      textTransform: "none",
    },
    smallTabRoot: {
      padding: theme.spacing(1, 2),
      margin: theme.spacing(0, 2),
    },
    mediumTabRoot: {
      padding: theme.spacing(1, 0),
      margin: theme.spacing(0, 3),
    },
    extraPaddingTop: {
      padding: theme.spacing(1.5, 2),
    },
    selectedTab: {
      color: theme.palette.primary.main,
    },
    selectedTabInverted: {
      color: theme.palette.common.black,
      opacity: 1,
    },
   
  })
);

const MultipleTabsBar = ({
  handleChange,
  value,
  tabs,
  loading,
  invertedColor,
  size = "small",
  extraPaddingTop,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Tabs
      value={value}
      onChange={(event, tabValue) => handleChange(tabValue)}
      aria-label="custom tabs"
      classes={{
        root: classes.tabsRoot,
        indicator: invertedColor
          ? classes.tabsIndicatorInverted
          : classes.tabsIndicator,
      }}
      data-test="h-menu-tabs"
      {...rest}
    >
      {tabs.map((prop, index) => (
        <ButtonTab
          name={prop.name}
          count={prop.count}
          icon={prop.icon}
          index={index}
          loading={loading}
          invertedColor={invertedColor}
          extraPaddingTop={extraPaddingTop}
          size={size}
          value={prop.value}
        />
      ))}
    </Tabs>
  );
};

MultipleTabsBar.defaultProps = {
  loading: false,
  handleChange: () => {},
  value: 0,
  tabs: [
    {
      name: "",
      count: 0,
    },
    {
      name: "",
      count: 0,
    },
  ],
};

export default MultipleTabsBar;



const ButtonTab = ({
  name,
  index,
  invertedColor,
  extraPaddingTop,
  size,
  ...rest
}) => {
  const fontVariant = size === "small" ? "caption" : "body2";
  const labelClass =
    size === "small" ? useStyles().smallLabel : useStyles().mediumLabel;
  const tabClass = extraPaddingTop
    ? useStyles().extraPaddingTop
    : size === "small"
    ? useStyles().smallTabRoot
    : useStyles().mediumTabRoot;

  return (
    <Tab
      label={
        <React.Fragment>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              variant={fontVariant}
              className={`${useStyles().label} ${labelClass}`}
            >
              {name}
              {"   "}
            </Typography>
          </Box>
        </React.Fragment>
      }
      key={index}
      classes={{
        root: `${useStyles().tabRoot} ${tabClass} ${invertedColor &&
          useStyles().selectedTabInverted}`,
        selected: invertedColor
          ? useStyles().selectedTabInverted
          : useStyles().selectedTab,
      }}
      data-test={"h-menu-tab"}
      {...rest}
    />
  );
};
