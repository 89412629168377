import React from "react";
import { StepperForm } from "../../organisms/StepperForm";
import Background from "../../templates/Background";
import Routes from "@skyflow/docs-config";

const StepperPage = () => {
  return (
    <Background
      title={"TOKENIZATION WITH SKYFLOW"}
      subtitle={
        "See how Skyflow's comprehensive tokenization solution makes it easy to collect, protect, and use sensitive data."
      }
      body={StepperForm}
      documentationLink={`https://docs.skyflow.com${Routes.tokenizationOverview}`}
    />
  );
};

export default StepperPage;
