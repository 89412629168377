import React from "react";
import MultipleTabsBar from "../MultipleTabs";
import TabPanel from "../TabsPanel";
import { Box } from "@material-ui/core";
import theme from "../../../utils/theme";

export const RequestResponseView = ({
  tabs,
  tabIndex,
  setTabIndex,
  response,
}) => {
  return (
    <>
      <MultipleTabsBar
        tabs={tabs.map((tab) => ({
          name: tab.title,
          content: tab.title,
        }))}
        value={tabIndex}
        handleChange={(e) => {
          setTabIndex(e);
        }}
        extraPaddingTop
        style={{ width: "100%" }}
      />
      <Box height="2px" mt={-0.5} bgcolor={theme.palette.grey[300]}></Box>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={tabIndex} index={index}>
          <pre
            style={{
              height: "405px",
              overflow: "auto",
              whiteSpace: "pre-wrap",
              marginBottom: "0px",
            }}
          >
            {Object.keys(response).length === 0 && tabIndex === 1
              ? "Please submit the form to view the response"
              : tab.content}
          </pre>
        </TabPanel>
      ))}
    </>
  );
};
