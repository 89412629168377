import axios from "axios";
import { getBaseUrl } from "../utils/helper";
import Qs from "qs";
import properties from "../utils/properties";

export const bulkGetRecords = (tableName, options, accessToken, callback) => {
  axios
    .get(getBaseUrl() + tableName, {
      headers: { Authorization: "Bearer " + accessToken },
      params: {
        skyflow_ids: options.skyflowIds,
        redaction: options.redaction,
        tokenization: options.tokenization,
        fields: options.fields,
        offset: options.offset,
        limit: options.limit,
      },
      paramsSerializer: function (params) {
        return Qs.stringify(params, { arrayFormat: "repeat" });
      },
    })
    .then((res) => {
      if (callback) {
        callback(res.data);
      }
      return res.data;
    })
    .catch(() => {
      callback("error");
    });
};

export const sendPaymentRequest = (body, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(properties.GATEWAY_URL, new URLSearchParams(body), {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer sk_test_51ME5LTSEalTk0qgGSgrMeRzzWeiKNaevutE6TxKrvY2soc3u68hBlJwffYK82MOUNLWuvtkAJ259B3NHFkn3nw6200HbOnFxdy",
          "X-Skyflow-Authorization": accessToken,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getRecord = (
  tableName,
  recordId,
  options = {},
  accessToken,
  callback
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(getBaseUrl() + tableName + `/${recordId}`, {
        headers: { Authorization: "Bearer " + accessToken },
        params: {
          redaction: options.redaction,
          tokenization: options.tokenization,
          fields: options.fields,
        },
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then((res) => {
        if (callback) {
          resolve(res.data);
          callback(res.data);
        }
      })
      .catch((err) => {
        reject(err);
        callback(err);
      });
  });
};

export const getTokenRecords = (recordIds, callback) => {
  return new Promise((resolve, reject) => {
    axios
      .post(properties.BEFFE_URL + `/tokens`, {
        skyflow_ids: recordIds,
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then((res) => {
        if (callback) {
          resolve(res.data);
          callback(res.data);
        }
      })
      .catch((err) => {
        reject(err);
        callback(err);
      });
  });
};

export const insertTokenRecord = (records) => {
  return new Promise((resolve, reject) => {
    axios
      .post(properties.BEFFE_URL + `/insertTokens`, {
        fields: records,
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteRecord = (tableName, recordId, accessToken, callback) => {
  return axios
    .delete(getBaseUrl() + tableName + "/" + recordId, {
      headers: { Authorization: "Bearer " + accessToken },
    })
    .then((res) => {
      if (callback) {
        callback(res.data);
      }
      return res.data;
    })
    .catch((err) => err && err.response && err.response.data);
};
