import React from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import theme from "../../../utils/theme";
import Union from "../../../../public/assets/Union.svg";
import { useConnections } from "./hook";
import adyen from "../../../../public/assets/adyen.svg";
import stripe from "../../../../public/assets/stripe.svg";
import experian from "../../../../public/assets/experian.svg";
import plaid from "../../../../public/assets/plaid.svg";
import visa from "../../../../public/assets/visa.svg";
import TabPanel from "../../molecules/TabsPanel";
import MultipleTabsBar from "../../molecules/MultipleTabs";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    width: "100%",
    borderRadius: "6px",
  },
  apiView: {
    background: theme.palette.grey[100],
    padding: "14px 5px 0px 14px",
    marginTop: "28px",
    maxHeight: "inherit",
  },

  buttonHighlight: {
    textTransform: "none",
    width: "95.5%",
    marginLeft: "10px",

    borderRadius: "6px",
    marginTop: "12px",
    padding: "5px",

    animation: "$pulse-black 1500ms infinite",
  },

  "@keyframes pulse-black": {
    "0%": {
      boxShadow: "0px 0px 2px 4px #4169e1",
    },

    "50%": {
      boxShadow: "0px 0px 0px 0px white",
    },

    "100%": {
      boxShadow: "0px 0px 2px 4px #4169e1",
    },
  },
}));

export const ConnectionsStep = ({
  activeStep,
  response,
  handleNext,
  paymentResponse,
  setPaymentResponse,
  accessToken
}) => {
  const classes = useStyles();
  const {
    handleRequest,
    lowerTabIndex,
    setLowerTabIndex,
    lowerTabs,
    sendRequestLoading,
    setSendRequestLoading,
    paymentError,
  } = useConnections(
    activeStep,
    response,
    handleNext,
    paymentResponse,
    setPaymentResponse,
    accessToken
  );
  const thirdPartyImages = [plaid, visa, stripe, experian, adyen];
  return (
    <Box>
      <Box style={{ paddingLeft: "25px" }}>
        <Typography
          variant="body2"
          style={{ marginTop: "4px", marginBottom: "4px" }}
        >
          With Skyflow Connections, you can directly call downstream APIs, like
          Stripe, with tokenized data. You never have to handle the raw,
          sensitive values. The request below makes a call to Stripe's
          createPaymentMethod API with the tokens from the first step.
        </Typography>
        <Box display="flex">
          <Box width="57%" className={classes.apiView}>
            <MultipleTabsBar
              tabs={lowerTabs.map((tab) => ({
                name: tab.title,
                content: tab.title,
              }))}
              value={lowerTabIndex}
              handleChange={(e) => {
                setLowerTabIndex(e);
              }}
              extraPaddingTop
              style={{ width: "100%" }}
            />
            <Box height="2px" mt={-0.5} bgcolor={theme.palette.grey[300]}></Box>
            {lowerTabs.map((tab, index) => (
              <TabPanel
                key={index}
                value={lowerTabIndex}
                index={index}
                style={{ height: "280px" }}
              >
                <pre
                  style={{
                    height: "inherit",
                    overflow: "auto",
                  }}
                >
                  {Object.keys(paymentResponse).length === 0 &&
                  lowerTabIndex === 1
                    ? "Please send the request to view the response"
                    : tab.content}
                </pre>
              </TabPanel>
            ))}
          </Box>
          <Box style={{ textAlign: "center" }} marginLeft={12} pr={10} pt={10}>
            <Box display="flex">
              <img src={Union}></img>
              <Box
                padding="30px"
                border="1px solid #EAF0F6"
                borderRadius="12px"
                marginLeft={12}
                boxShadow={theme.shadows[1]}
              >
                {thirdPartyImages.map((image) => (
                  <Box marginTop="20px">
                    <img src={image}></img>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          textAlign: "center",
          marginTop: "28px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          style={{
            width: "370px",
          }}
          className={
            !(activeStep != 2 || sendRequestLoading)
              ? classes.buttonHighlight
              : classes
          }
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{
              width: "360px",
              height: "36px",
              fontSize: "16px",
            }}
            disabled={activeStep != 2 || sendRequestLoading ? true : false}
            startIcon={
              sendRequestLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                undefined
              )
            }
            onClick={() => {
              setSendRequestLoading(true);
              handleRequest();
            }}
          >
            {sendRequestLoading ? "Sending Request" : "Send Request"}
          </Button>
          {paymentError && (
            <Typography
              variant="overline"
              color="error"
              style={{ textTransform: "none" }}
            >
              Something went wrong while submitting data.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
