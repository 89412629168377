import { Box } from '@material-ui/core';
import React from 'react';

const TabPanel = (props) => {
  const { children, value, index, remount, ...rest } = props;
  const [isDisplayed, setIsDisplayed] = React.useState(false);

  React.useEffect(() => {
    if (!isDisplayed && value === index) {
      setIsDisplayed(true);
    }
  }, [value, index]);

  return remount ? (
    index == value ? (
      <Box {...rest} width="100%">
        {children}
      </Box>
    ) : (
      <Box />
    )
  ) : (
    <Box {...rest} display={index == value ? 'block' : 'none'} width="100%">
      {isDisplayed && children}
    </Box>
  );
};

export default React.memo(TabPanel);
