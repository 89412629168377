import React from "react";
import { Tabs, Tab, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../utils/theme";

const useStyles = makeStyles({
  tabsRoot: {
    minHeight: "28px",
  },
  scroller: {},
  tabRoot: {
    padding: "0px",
    minHeight: "0px",
    minWidth: "146px",
    textTransform: "none",
  },
  tabRootWithAnimation: {
    padding: "0px",
    minHeight: "0px",
    width: "146px",
    textTransform: "none",
  },
  indicator: {
    backgroundColor: "transparent",
  },
  tabTextColor: {
    opacity: "unset",
  },
  tabHighlight: {
    borderRadius: "2px",
    margin: "2px 0px 2px 8px",
    padding: "2px",

    animation: "$pulse-black 1500ms infinite",
  },
  "@keyframes pulse-black": {
    "0%": {
      boxShadow: "0px 0px 2px 3px #4169e1",
    },

    "50%": {
      boxShadow: "0px 0px 2px 3px white",
    },

    "100%": {
      boxShadow: "0px 0px 2px 3px #4169e1",
    },
  },
});

export const ButtonTabsWrapper = ({ tableTab, setTableTab, activeStep }) => {
  const classes = useStyles();
  return (
    <Tabs
      classes={{
        root: classes.tabsRoot,
        indicator: classes.indicator,
        scroller: classes.scroller,
      }}
      value={tableTab}
      onChange={(e, value) => setTableTab(value)}
      textColor="primary"
      centered={true}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="38px"
      >
        <Box onClick={() => setTableTab(0)}>
          <Tab
            style={{
              background:
                tableTab == 0
                  ? theme.palette.primary.main
                  : theme.palette.grey[300],
              color: tableTab == 0 ? "white" : theme.palette.text.secondary,
              borderRadius: "2px",
              height: "24px",
            }}
            classes={{
              root: classes.tabRoot,
              textColorInherit: classes.tabTextColor,
            }}
            label="Skyflow Vault"
            value={0}
          ></Tab>
        </Box>
        <Box
          marginLeft="8px"
          classes={{
            root:
              activeStep == 1 && tableTab !== 1
                ? classes.tabHighlight
                : classes.tabRoot,
          }}
          onClick={() => setTableTab(1)}
        >
          <Tab
            style={{
              background:
                tableTab == 1
                  ? theme.palette.primary.main
                  : theme.palette.grey[300],
              color: tableTab == 1 ? "white" : theme.palette.text.secondary,
              borderRadius: "2px",
              height: "24px",
            }}
            classes={{
              root: classes.tabRootWithAnimation,
              textColorInherit: classes.tabTextColor,
            }}
            label="Tokenized Database"
            value={1}
          ></Tab>
        </Box>
      </Box>
    </Tabs>
  );
};
