import React, { useContext } from "react";
import { getBearerToken } from "../helper";
import properties from "../properties";
import Skyflow from 'skyflow-js';


const skyflow = Skyflow.init({
  vaultID: properties.VAULT_ID,
  vaultURL: properties.VAULT_URL,
  options: { logLevel:Skyflow.LogLevel.ERROR,env:Skyflow.Env.DEV },
  getBearerToken: getBearerToken
});

const SkyflowContext = React.createContext(skyflow);

export const useSkyflow = () => useContext(SkyflowContext);

export default SkyflowContext;
