import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import React, { Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import theme from "./utils/theme";
import { SnackbarProvider } from "notistack";

import { CREDIT_CARD_ROUTE } from "./utils/routes";
import StepperPage from "./components/pages/StepperPage";

const Next = React.createContext();

export const useNextHook = () => React.useContext(Next);

const App = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>
          <Fragment>
            <Switch>
              <Route path={CREDIT_CARD_ROUTE} exact component={StepperPage} />

              <Redirect to={CREDIT_CARD_ROUTE} />
            </Switch>
          </Fragment>
        </SnackbarProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
