import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import theme from "../../../utils/theme";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[900],
  },
  tooltipArrow: {
    color: theme.palette.grey[900],
  },
  icon: {
    padding: 0,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

export const FormFooter = ({ collectError }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginTop={collectError ? "5px" : "17px"}
    >
      <Typography variant="body2" color="textSecondary">
        This form is built using secure form elements.
      </Typography>
      <Tooltip
        interactive
        arrow
        title={
          <>
            You can securely collect and display sensitive data in the browser
            with Skyflow's client-side SDKs. To learn more, see the{" "}
            <a
              href="https://github.com/skyflowapi/skyflow-js"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p
                style={{
                  color: theme.palette.common.white,
                  margin: "0px",
                  textDecoration: "underline",
                }}
              >
                docs
              </p>
            </a>
          </>
        }
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.tooltipArrow,
        }}
      >
        <IconButton className={classes.icon}>
          <InfoOutlinedIcon
            fontSize="small"
            style={{
              marginLeft: "6px",
              fill: theme.palette.grey[500],
              cursor: "pointer",
            }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
