import React from "react";
import { sendPaymentRequest } from "../../../services";
import properties from "../../../utils/properties";

export const useConnections = (activeStep,response,handleNext,paymentResponse,setPaymentResponse,accessToken) => {
  const [sendRequestLoading, setSendRequestLoading] = React.useState(false);
  
  const [lowerTabIndex, setLowerTabIndex] = React.useState(0);
  const [paymentError, setPaymentError] = React.useState(false);


  const handleRequest = () => {
    if (response && response.records) {
      sendPaymentRequest(
        {
          type: "card",
          "card[number]": `${response.records[0].fields.card_number}`,
          "card[exp_month]": `${response.records[0].fields.expiry_month}`,
          "card[exp_year]": `${response.records[0].fields.expiry_year}`,
        },
        accessToken
      )
        .then((res) => {
          setPaymentResponse(res.data);
          setLowerTabIndex(1);
          setSendRequestLoading(false);
          setPaymentError(false);
          handleNext();
        })
        .catch(() => {
          setSendRequestLoading(false);
          setPaymentError(true);
        });
    } else {
      setSendRequestLoading(false);
      setPaymentError(true);
    }
  };

  const sendTokenizedRequest = `curl 
  ${properties.GATEWAY_URL} \\
  -u sk_test_4eC39HqLyjWDarjtT1zdp7dc: \\
  -H 'X-Skyflow-Authorization: YOUR API KEY HERE' \\
  -d type=card \\
  -d "card[number]"=${
    response && response.records ? response.records[0].fields.card_number : ""
  } \\
  -d "card[exp_month]"=${
    response && response.records ? response.records[0].fields.expiry_month : ""
  } \\
  -d "card[exp_year]"=${
    response && response.records ? response.records[0].fields.expiry_year : ""
  } \\`;

  const lowerTabs = [
    {
      title: "Request",
      content: sendTokenizedRequest,
    },
    {
      title: "Response",
      content: JSON.stringify(paymentResponse, undefined, 2),
    },
  ];

  return {
    handleRequest,
    lowerTabIndex,
    setLowerTabIndex,
    lowerTabs,
    sendRequestLoading,
    setSendRequestLoading,
    paymentResponse,
    paymentError,
  };
};
