import React from "react";
import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme, { extraColors } from "../../../utils/theme";
import { FORM_FIELDS } from "../../../utils/constants";
import _ from "lodash";
import { ButtonTabsWrapper } from "../../molecules/ButtonTabsWrapper";

const useStyles = makeStyles({
  table: {
    width: "100%",
    borderCollapse: "inherit",
  },
  thead: {
    background: theme.palette.grey[100],
  },
  tRow: {
    height: "37px",
  },
  headCell: {
    padding: "0px",
    fontFamily: "Inter",
    textAlign: "center",
    border: `0.2px solid ${theme.palette.grey[200]}`,
    background: theme.palette.grey[100],
  },
  tCell: {
    padding: "2px",
    border: `0.2px solid ${theme.palette.grey[200]}`,
    fontFamily: "Inter",
    textAlign: "center",
  },
  tableBorder: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "4px",
    marginTop: "12px",
    height: "350px",
    overflow: "overlay",
  },
  loading: {
    color: theme.palette.grey[500],
    padding: "1px",
  },
  button: {
    backkground: theme.palette.primary.main,
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "4px",
      height: "2px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[500],
      borderRadius: "20px",
    },
  },
});

const VaultTable = ({
  rows,
  error,
  loading,
  tokenRows,
  insertedRow,
  tokenizeError,
  tokensLoading,
  handleNext,
  activeStep,
  tableTab,
  setTableTab,
}) => {
  const classes = useStyles();

  const [tableRows, setTableRows] = React.useState(tableTab ? tokenRows : rows);

  React.useEffect(() => {
    if (tableTab == 1 && activeStep == 1) {
      handleNext();
    }
  }, [tableTab]);

  React.useEffect(() => {
    rows && rows.length > 0 && setTableRows(tableTab ? tokenRows : rows);
  }, [rows, tokenRows, tableTab, insertedRow]);

  const columns = [
    "name_on_card",
    "card_number",
    "expiration_month",
    "expiration_year",
  ];
  return (
    <>
      <ButtonTabsWrapper
        tableTab={tableTab}
        setTableTab={setTableTab}
        activeStep={activeStep}
      />

      <TableContainer className={classes.tableBorder}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          {rows && rows.length === 0 && (
            <caption style={{ textAlign: "center" }}>
              <Typography variant="caption">No rows</Typography>
            </caption>
          )}
          {(error || (tokenizeError && tableTab === 1)) && (
            <caption style={{ textAlign: "center" }}>
              <Typography variant="caption">Unable to fetch data</Typography>
            </caption>
          )}
          {((loading && tableTab === 0) ||
            (tokensLoading && tableTab === 1)) && (
            <caption style={{ textAlign: "Center" }}>
              <CircularProgress
                thickness={3}
                size={20}
                classes={{ root: classes.loading }}
              />
            </caption>
          )}
          <TableHead classes={{ root: classes.thead }}>
            <TableRow classes={{ root: classes.tRow }}>
              {columns.map((column) => (
                <TableCell classes={{ root: classes.headCell }}>
                  <Typography variant="caption" style={{ fontFamily: "Inter" }}>
                    {column}
                  </Typography>{" "}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableRows &&
              ((!error && tableTab === 0) ||
                (!tokenizeError && tableTab === 1)) &&
              tableRows.map(
                (row, index) =>
                  row.fields && (
                    <TableRow
                      classes={{ root: classes.tRow }}
                      key={index}
                      style={{
                        backgroundColor:
                          insertedRow &&
                          row.fields.skyflow_id ===
                            insertedRow.fields.skyflow_id
                            ? extraColors.yellow
                            : "transparent",
                      }}
                    >
                      <TableCell
                        classes={{ root: classes.tCell }}
                        component="th"
                        scope="row"
                      >
                        {_.get(row.fields, FORM_FIELDS.name)}
                      </TableCell>
                      <TableCell classes={{ root: classes.tCell }}>
                        {_.get(row.fields, FORM_FIELDS.card_number)}
                      </TableCell>
                      <TableCell classes={{ root: classes.tCell }}>
                        {_.get(row.fields, FORM_FIELDS.expiry_month) ||
                          row.expiryMonth}
                      </TableCell>
                      <TableCell classes={{ root: classes.tCell }}>
                        {_.get(row.fields, FORM_FIELDS.expiry_year) ||
                          row.expiryYear}
                      </TableCell>
                    </TableRow>
                  )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default VaultTable;
