export const TABLE_NAME = "persons";

export const FORM_FIELDS = {
  name: "name_on_card",
  card_number: "card_number",
  expiry_month: "expiry_month",
  expiry_year: "expiry_year",
  cvv: "cvv",
};

export const QUERY_FIELDS = [
  "skyflow_id",
  "name_on_card",
  "card_number",
  "expiry_month",
  "expiry_year",
];
