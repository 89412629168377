import React from "react";
import theme from "../../../utils/theme";
import { deleteRecord, insertTokenRecord } from "../../../services";
import { useSkyflow } from "../../../utils/skyflowjs";
import mockdata from "../../../utils/mockData/mockData.json";
import Skyflow from "skyflow-js";
import { TABLE_NAME, FORM_FIELDS } from "../../../utils/constants";
import properties from "../../../utils/properties";

export const useCardDetails = (
  activeStep,
  accessToken,
  setTokens,
  tokens,
  handleNext,
  response,
  setResponse
) => {
  const options = {
    inputStyles: {
      base: {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: "10px 16px",
        "border-radius": "4px",
        color: theme.palette.text.primary,
      },
      complete: {
        color: theme.palette.text.primary,
      },
      empty: {},
      focus: {},
      invalid: {
        border: "1px solid #f44336",
      },
    },
    labelStyles: {
      styles: {
        base: {
          color: theme.palette.text.secondary,
          "font-family":
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          fontWeight: 500,
          fontSize: "12px",
        },
      },
    },
    errorTextStyles: {
      base: {
        fontSize: "14px",
        "font-family": "Roboto, sans-serif",
      },
    },
  };

  const nameRef = React.createRef();
  const cardRef = React.createRef();
  const monthRef = React.createRef();
  const yearRef = React.createRef();
  const cvvRef = React.createRef();

  const [container, setContainer] = React.useState();
  const [cvvContainer, setCVVContainer] = React.useState();

  const [nameField, setName] = React.useState("");
  const [cardNum, setCardNum] = React.useState("");
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");

  const skyflow = useSkyflow();
  const [collectLoading, setCollectLoading] = React.useState(false);
  const [tempResponse, setTempResponse] = React.useState({});
  const [tabIndex, setTabIndex] = React.useState(0);
  const [collectError, setCollectError] = React.useState(false);

  const [elementState, setElementState] = React.useState({
    nameElement: null,
    monthElement: null,
    yearElement: null,
    cardNumberElement: null,
    cvvElement: null,
  });
  const [isNameValid, setIsNameValid] = React.useState(false);
  const [isCardValid, setIsCardValid] = React.useState(false);
  const [isMonthValid, setIsMonthValid] = React.useState(false);
  const [isYearValid, setIsYearValid] = React.useState(false);
  const [isCvvValid, setIsCvvValid] = React.useState(false);

  React.useEffect(() => {
    const container = skyflow.container("COLLECT");
    setContainer(container);
    const cvvContainer = skyflow.container("COLLECT");
    setCVVContainer(cvvContainer);

    const regexMatchRule = {
      type: Skyflow.ValidationRuleType.REGEX_MATCH_RULE,
      params: {
        regex: /^([a-zA-Z]+|([a-zA-Z ]+[a-zA-Z]))$/,
        error: "Please enter a valid name", // optional, default error is "VALIDATION FAILED"
      },
    };

    const name = container.create({
      table: TABLE_NAME,
      column: FORM_FIELDS.name,
      ...options,
      validations: [regexMatchRule],
      type: Skyflow.ElementType.CARDHOLDER_NAME,
      placeholder: "Eg: Rachel Green",
    });

    const cvvElement = cvvContainer.create({
      table: TABLE_NAME,
      column: FORM_FIELDS.cvv,
      ...options,
      disabled: true,
      placeholder: "123",
      type: Skyflow.ElementType.CVV,
    });

    const cardNumber = container.create({
      table: TABLE_NAME,
      column: FORM_FIELDS.card_number,
      ...options,
      disabled: true,

      placeholder: "1234-4567-1234-5678",
      type: Skyflow.ElementType.CARD_NUMBER,
    });

    const expiryMonth = container.create({
      table: TABLE_NAME,
      column: FORM_FIELDS.expiry_month,
      ...options,
      disabled: true,

      placeholder: "11",
      type: Skyflow.ElementType.INPUT_FIELD,
    });
    const expiryYear = container.create({
      table: TABLE_NAME,
      column: FORM_FIELDS.expiry_year,
      ...options,
      disabled: true,

      placeholder: "2027",
      type: Skyflow.ElementType.INPUT_FIELD,
    });

    setElementState({
      nameElement: name,
      monthElement: expiryMonth,
      yearElement: expiryYear,
      cardNumberElement: cardNumber,
      cvvElement: cvvElement,
    });

    if (nameRef && cardRef && monthRef && yearRef && cvvRef) {
      name.mount(nameRef.current);
      cardNumber.mount(cardRef.current);
      expiryMonth.mount(monthRef.current);
      expiryYear.mount(yearRef.current);
      cvvElement.mount(cvvRef.current);

      name.on("CHANGE", (data) => {
        setName(data.value);
        if (data.isEmpty || !data.isValid) {
          setIsNameValid(false);
        } else {
          setIsNameValid(true);
        }
      });

      cardNumber.on("CHANGE", (data) => {
        setCardNum(data.value);
        if (data.isEmpty || !data.isValid) {
          setIsCardValid(false);
        } else {
          setIsCardValid(true);
        }
      });

      expiryMonth.on("CHANGE", (data) => {
        setMonth(data.value);
        if (data.isEmpty || !data.isValid) {
          setIsMonthValid(false);
        } else {
          setIsMonthValid(true);
        }
      });

      expiryYear.on("CHANGE", (data) => {
        setYear(data.value);
        if (data.isEmpty || !data.isValid) {
          setIsYearValid(false);
        } else {
          setIsYearValid(true);
        }
      });

      cvvElement.on("CHANGE", (data) => {
        if (data.isEmpty || !data.isValid) {
          setIsCvvValid(false);
        } else {
          setIsCvvValid(true);
        }
      });
    }
  }, []);

  React.useEffect(() => {
    tempResponse &&
      tempResponse.records &&
      insertTokenRecord(tempResponse.records[0].fields)
        .then(() => {
          setResponse(tempResponse);
          setCollectLoading(false);

          setTabIndex(1);
          handleNext();
        })
        .catch(() => {
          deleteRecord(
            TABLE_NAME,
            tempResponse.records[0].fields.skyflow_id,
            accessToken,
            () => {
              setCollectError(true);
              setCollectLoading(false);
            }
          );
        });
  }, [tempResponse]);

  const request = `curl -i -X POST \\
  ${properties.VAULT_URL}
  /v1/vaults/${properties.VAULT_ID}/${TABLE_NAME} \\
  -H 'Authorization: YOUR_API_KEY_HERE' \\
  -H 'Content-Type: application/json' \\
  -d '{
    "records": [
      {
        "fields": {
          "name_on_card": "${nameField}",
          "card_number": "${cardNum}",
          "expiry_month": "${month}",
          "expiry_year": "${year}",
        }
      }
    ]
  }'`;

  const tabs = [
    {
      title: "Request",
      content: request,
    },
    { title: "Response", content: JSON.stringify(response, undefined, 2) },
  ];

  const handleGenerate = () => {
    setCollectError(false);

    const mockIndex = Math.round(Math.random() * 7);
    elementState.yearElement.setValue(
      mockdata[mockIndex].expiry_date.split("/")[1]
    );
    elementState.monthElement.setValue(
      mockdata[mockIndex].expiry_date.split("/")[0]
    );
    elementState.cardNumberElement.setValue(mockdata[mockIndex].card_number);
    elementState.cvvElement.setValue(mockdata[mockIndex].cvv);
  };

  const handleSubmitCardDetails = () => {
    setCollectLoading(true);
    const options = {
      additionalFields: {
        records: [
          {
            table: TABLE_NAME,
            fields: {
              created_timestamp: new Date().toISOString().slice(0, 19),
            },
          },
        ],
      },
    };
    try {
      container
        .collect(options)
        .then((res) => {
          setTempResponse(res);
          setCollectError(false);
          1;
        })
        .catch(() => {
          setCollectLoading(false);
          setCollectError(true);
        });
    } catch (e) {
      setCollectLoading(false);
      setCollectError(true);
    }
  };
  const isButtonDisabled = () => {
    return (
      !isNameValid ||
      !isCardValid ||
      !isMonthValid ||
      !isYearValid ||
      !isCvvValid ||
      collectLoading ||
      (response && response.records ? true : false)
    );
  };

  return {
    container,
    cvvContainer,
    accessToken,
    collectLoading,
    response,
    tempResponse,
    tabIndex,
    collectError,
    tabs,
    handleGenerate,
    setCollectLoading,
    setCollectError,
    setTabIndex,
    handleSubmitCardDetails,
    nameRef,
    cardRef,
    monthRef,
    yearRef,
    cvvRef,
    isSubmitDisabled: isButtonDisabled(),
  };
};
